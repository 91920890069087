import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Expertise } from 'views/index/expertises-section/components/expertise';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { arrayOf, bool, node, shape, string } from 'prop-types';

const SContainer = styled(Container)`
    background-color: var(--gray-color-90);
`;

const SSWrapper = styled.div`
    padding: 7.5rem 0;
    ${CONSTANTS.MEDIA.max1024`
        padding: 6.5rem 0;
    `}
`;

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    margin-bottom: 4.375rem;
    ${CONSTANTS.MEDIA.max1024`
        margin-bottom: 2.25rem;
    `}
`;

const SWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4.375rem 2.5rem;
    ${CONSTANTS.MEDIA.max1024`
        grid-template-columns: 1fr 1fr;
    `}
    ${CONSTANTS.MEDIA.max768`
        grid-template-columns: 1fr;
    `}
`;

export const ExpertisesWallSection = ({ data, showTitle }) => {
    const expertisesToRender = data.map((expertise) => (
        <Expertise key={expertise.title} expertise={expertise} />
    ));

    return (
        <SContainer id="expertises">
            <SSWrapper>
                {showTitle && (
                    <SHeaderSecond topParagraph="main-page.expertisesSection.expertises">
                        <FormattedMessage id="main-page.expertisesSection.weSpecializeWith" />
                    </SHeaderSecond>
                )}
                <SWrapper>{expertisesToRender}</SWrapper>
            </SSWrapper>
        </SContainer>
    );
};

ExpertisesWallSection.propTypes = {
    showTitle: bool,
    data: arrayOf(
        shape({
            icon: node,
            title: string,
            description: string,
            to: string,
        }),
    ).isRequired,
};

ExpertisesWallSection.defaulProps = {
    showTitle: true,
};
